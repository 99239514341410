import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A tippy instance is an individual tippy object. It has a bunch of properties and
methods that contain information and functionality to manipulate the tippy
programmatically.`}</p>
    <h3>{`Accessing an instance`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy()`}</code>{` creates new instances. It returns a single instance or an array of
instances depending on the type of argument it's supplied.`}</p>
    <h4>{`Element`}</h4>
    <p>{`The instance is directly returned, as this represents a single target:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// type Instance`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instance`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token dom variable"
          }}>{`document`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`String, NodeList, Element[]`}</h4>
    <p>{`An array of instances are returned, as these represent multiple targets:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// type Instance[]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instances1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instances2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`[`}</span>{`element1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` element2`}<span parentName="code" {...{
            "className": "token block"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instances3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token dom variable"
          }}>{`document`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`.btn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4><code parentName="h4" {...{
        "className": "language-text"
      }}>{`_tippy`}</code>{` property`}</h4>
    <p>{`If you need to access the instance later, this property can be useful if you
didn't assign the instance(s) to a variable:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`button`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token dom variable"
          }}>{`document`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instance`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token object"
          }}>{`button`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`_tippy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Both the reference element and the popper element have the instance attached as
this property.`}</p>
    <h3>{`Shape and properties`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`instance`}</code>{` is a plain object. It's best to log it out yourself and investigate
in DevTools:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token console"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`instance`}<span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      